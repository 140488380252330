// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { FC, PropsWithChildren } from 'react';
import { FeatureContext } from './FeatureContext';

export type FeatureSettings = {
  [key: string]: boolean | undefined;
};

interface Props extends PropsWithChildren {
  featureSettings: FeatureSettings;
}

export const FeatureContextProvider: FC<Props> = ({
  featureSettings,
  ...props
}) => {
  const getIsFeatureEnabled = (featureId: string) => {
    return featureSettings?.[featureId];
  };

  return (
    <FeatureContext.Provider
      value={{
        getIsFeatureEnabled,
      }}
      {...props}
    />
  );
};
