// Copyright © 2020 Niphtio, Inc.
// All Rights Reserved.

import flowRight from 'lodash/flowRight';
import { NextPage } from 'next';
import { withApollo } from '~/common/apollo';
import { SSFeatureSettings } from '~/common/apollo/withProps/withFeatureContext';
import { withSSColorMode } from '~/common/apollo/withProps/withSSColorMode';
import { withSSCookie } from '~/common/apollo/withProps/withSSCookie';
import { PageType } from '~/common/utilities/pageInfo';
import { AuthContext, AuthContextProps } from '~/containers/common/AuthContext';
import { FeatureContextProvider } from '~/containers/common/FeatureContext/FeatureContextProvider';
import { SEO } from '~/containers/common/SEO';
import { TodoPage } from '~/containers/pages/TodoPage/TodoPage';

interface Props extends AuthContextProps, SSFeatureSettings {}

const Page: NextPage<Props> = ({ featureSettings, ...props }) => {
  return (
    <>
      <SEO />
      <AuthContext {...props}>
        <FeatureContextProvider featureSettings={featureSettings}>
          <TodoPage todoPage={PageType.Todo} />
        </FeatureContextProvider>
      </AuthContext>
    </>
  );
};

export const getServerSideProps = flowRight(withSSColorMode, withSSCookie)();

export default flowRight(withApollo)(Page);
